import * as React from "react";
import { useContext } from "react";
import { styled, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import DownIcon from "../images/down.svg";
import { px } from "../utilities/px";

const ratio = 18 / 40;

const DownArrowContain = styled("div", () => {
  const theme = useContext(ThemeContext);
  return {
    width: px(38 * ratio),
    height: px(38),
    [theme.MediaQueries.desktop]: {
      width: px(58 * ratio),
      height: px(58),
    },
  };
});

const DownArrow = (...params: Parameters<typeof DownArrowContain>) => {
  const [css] = useStyletron();
  const [props] = params;
  return (
    <DownArrowContain {...props}>
      <DownIcon className={css({ width: "100%", height: "100%" })} />
    </DownArrowContain>
  );
};

export default DownArrow;
