import * as React from "react";
import { useContext } from "react";
import { styled, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import AdidasLogo from "../images/adidas.svg";
import { px } from "../utilities/px";

const RATIO = 61 / 90;

const LogoContain = styled<
  "div",
  { logo?: any; $ratio?: number; $width?: number; $desktopWidth?: number }
>(
  "div",
  ({
    $ratio: ratio = RATIO,
    $width: width = 60,
    $desktopWidth: desktopWidth = 90,
  }) => {
    const theme = useContext(ThemeContext);
    return {
      width: px(width),
      height: px(width * ratio),
      [theme.MediaQueries.desktop]: {
        width: px(desktopWidth),
        height: px(desktopWidth * ratio),
      },
    };
  }
);

const Logo = (...params: Parameters<typeof LogoContain>) => {
  const [css] = useStyletron();
  const [{ logo: LogoOverride, ...props }] = params;

  const Logo = LogoOverride ?? AdidasLogo;

  return (
    <LogoContain {...props}>
      <Logo className={css({ width: "100%", height: "100%" })} />
    </LogoContain>
  );
};

Logo.RATIO = RATIO;

export default Logo;
