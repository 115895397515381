import * as React from "react";
import { useContext } from "react";
import { StyleObject, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const TechSection = ({
  pull,
  image,
  imageStyles = {},
  contentStyles = {},
  desktopContentStyles = {},
  children,
  desktopSectionStyles = {},
}: {
  pull: "left" | "right";
  image: string;
  imageStyles?: StyleObject;
  contentStyles?: StyleObject;
  desktopContentStyles?: StyleObject;
  children: any;
  desktopSectionStyles?: StyleObject;
}) => {
  const [css] = useStyletron();
  const theme = useContext(ThemeContext);
  const isPullLeft = pull === "left";

  return (
    <section
      className={css({
        display: "flex",
        flexDirection: "column",
        [theme.MediaQueries.desktop]: {
          flexDirection: isPullLeft ? "row" : "row-reverse",
          alignSelf: isPullLeft ? "flex-end" : "flex-start",
          width: "100%",
          marginBottom: px(150),
          ...desktopSectionStyles,
        },
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
        })}
      >
        <img
          src={image}
          alt="Carbon Fiber Speedframe"
          className={css({
            width: "100%",
            alignSelf: "center",
            ...imageStyles,
          })}
        />
      </div>
      <div
        className={css({
          boxSizing: "border-box",
          width: px(320),
          paddingLeft: px(40),
          paddingRight: px(40),
          paddingTop: px(40),
          paddingBottom: px(60),
          ...contentStyles,
          [theme.MediaQueries.desktop]: {
            minWidth: px(320),
            maxWidth: px(320),
            width: "100%",
            paddingLeft: px(0),
            paddingRight: px(0),
            paddingTop: px(0),
            paddingBottom: px(0),
            [!isPullLeft ? "marginLeft" : "marginRight"]: px(40),
            [isPullLeft ? "marginLeft" : "marginRight"]: px(100),
            alignSelf: "flex-end",
            ...desktopContentStyles,
          },
        })}
      >
        {children}
      </div>
    </section>
  );
};

export default TechSection;
