import * as React from "react";
import { useState } from "react";
import { useStyletron } from "styletron-react";
import { RetailerContext } from "../contexts/RetailerContext";
import { useRetailer } from "../hooks/useRetailer";
import { useRetailerContext } from "../hooks/useRetailerContext";
import { useTag } from "../hooks/useTag";
import Retailers from "../types/Retailers";
import { px } from "../utilities/px";
import Input from "./Input";
import Search from "./Search";

interface IRetailerSelectProps {
  color: string;
  borderColor?: string;
  retailers: Record<string, Retailers>;
  zIndex?: number;
}

const RetailerSelect = ({
  color,
  borderColor,
  zIndex = undefined,
  retailers = {},
}: IRetailerSelectProps) => {
  const [retailer, setRetailer] = useState("");
  const [isSecondAttempt, setIsSecondAttempt] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { setRetailer: setRetailerInContext } = useRetailerContext();
  const { tag } = useTag();
  const [css] = useStyletron();

  const onEnter = (e: React.FormEvent) => {
    e.preventDefault();

    tag("retailer_attempt", { input: retailer });

    const normalizedRetailer = retailer
      .trim()
      .toLocaleLowerCase()
      .replace(/[\W_]+/g, "");

    if (normalizedRetailer.length > 0) {
      setIsEmpty(false);

      const matchedRetailer = retailers[normalizedRetailer];

      // if we match, send them along
      if (matchedRetailer) {
        tag("retailer_matched", { retailer: matchedRetailer });
        setRetailerInContext(matchedRetailer);
      } else {
        if (!isSecondAttempt) {
          // if it's their second attempt, have them confirm
          setIsSecondAttempt(true);
        } else {
          // otherwise, if it's their second attempt and still no match
          // send them through and log it
          tag("retailer_unknown", { input: retailer });
          setRetailerInContext(retailer);
          setIsSecondAttempt(false);
        }
      }

      // always clear
      setRetailer("");
    } else {
      setIsEmpty(true);
    }
  };

  return (
    <div className={css({ position: "relative", zIndex })}>
      <form onSubmit={onEnter}>
        <Input
          icon={<Search />}
          color={color}
          borderColor={borderColor}
          placeholder="Retailer"
          value={retailer}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRetailer(e.target.value);
          }}
        />
        {isEmpty && (
          <div
            className={css({
              color,
              position: "absolute",
              top: "100%",
              textTransform: "uppercase",
              marginTop: px(8),
              textAlign: "center",
            })}
          >
            Please enter a retailer.
          </div>
        )}
        {!isEmpty && isSecondAttempt && (
          <div
            className={css({
              color,
              position: "absolute",
              top: "100%",
              textTransform: "uppercase",
              marginTop: px(8),
              textAlign: "center",
            })}
          >
            Retailer not found, try again.
          </div>
        )}
      </form>
    </div>
  );
};

export default RetailerSelect;
