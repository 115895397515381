import { useSessionStorage } from "usehooks-ts";

export function useRetailer() {
  const [retailer, setRetailer] = useSessionStorage("retailer", undefined);

  return {
    retailer,
    setRetailer,
  };
}
