import { useContext } from "react";
import { RetailerContext } from "../contexts/RetailerContext";

export function useRetailerContext() {
  const { retailer, setRetailer } = useContext(RetailerContext);
  return {
    retailer,
    setRetailer,
  };
}
