import * as React from "react";
import { useContext } from "react";
import { styled, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import RightIcon from "../images/right.svg";
import { px } from "../utilities/px";

const ratio = 16 / 30;

const RightArrowContain = styled("div", () => {
  const theme = useContext(ThemeContext);
  return {
    width: px(17),
    height: px(17 * ratio),
    marginLeft: px(15),
    lineHeight: 0,
    [theme.MediaQueries.desktop]: {
      width: px(28),
      height: px(28 * ratio),
    },
  };
});

const RightArrow = (...params: Parameters<typeof RightArrowContain>) => {
  const [css] = useStyletron();
  const [props] = params;
  return (
    <RightArrowContain {...props}>
      <RightIcon className={css({ width: "100%", height: "100%" })} />
    </RightArrowContain>
  );
};

export default RightArrow;
