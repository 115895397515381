import { useContext } from "react";
import { styled } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const Header3 = styled("h3", (props) => {
  const theme = useContext(ThemeContext);

  return {
    fontFamily: "adineue PRO Cond",
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    marginTop: 0,
    marginBottom: px(20),
    fontSize: px(40),
    lineHeight: px(40),
    [theme.MediaQueries.desktop]: {
      fontSize: px(60),
      lineHeight: px(60),
    },
  };
});

export default Header3;
