const red = "E63320";

const CONSTANTS = {
  RED: `#${red}`,
  RED_NO_HASH: red,
  NEEDS_REPLACED_ASSET: {
    filter: `opacity(35%) invert(100%)`,
    [`&:before`]: {
      display: "block",
      content: "replace this",
    },
  },
};

export default CONSTANTS;
