import React from "react";
import { styled } from "styletron-react";
import Content from "../components/Content";
import {
  FeatureComparison,
  ShoeDetails,
} from "../components/FeatureComparison";
import Header4 from "../components/Header4";
import imagePPlus from "../images/adisoccer/predator-accuracy/p+.png";
import imageP1 from "../images/adisoccer/predator-accuracy/p1.png";
import imageP2 from "../images/adisoccer/predator-accuracy/p2.png";
import imageP3 from "../images/adisoccer/predator-accuracy/p3.png";
import imageP3L from "../images/adisoccer/predator-accuracy/p3l.png";
import imageP4 from "../images/adisoccer/predator-accuracy/p4.png";

const shoeTypes = ["P+", "P1", "P2", "P3", "P3L", "P4"] as const;
type ShoeType = typeof shoeTypes[number];

const UL = styled("ul", {});
const LI = styled("li", { marginBottom: "0.5rem" });

const shoes: Record<ShoeType, ShoeDetails> = {
  "P+": {
    name: "Predator P+",
    price: "$280",
    image: imagePPlus,
    content: ({}) => (
      <div>
        <Header4>Fit</Header4>
        <Content $as="div">
          <UL>
            <LI>
              Laceless with stretchable PRIMEKNIT tongue material allows for
              easy entry and provides a clean strike area.
            </LI>
          </UL>
        </Content>
        <Header4>Touch</Header4>
        <Content $as="div">
          <UL>
            <LI>
              High-definition grip technology: individual rubber elements in the
              kicking zone for enhanced grip on the ball while maintaining a
              soft upper feel.
            </LI>
            <LI>
              Contains soft soft and flexible microfiber Hybridtouch material
              more moulded fit, weight reduction and a soft touch on the ball.
            </LI>
          </UL>
        </Content>
        <Header4>Movement</Header4>
        <Content $as="div">
          <UL>
            <LI>
              A revised FACETFRAME split tooling delivers stability, highly
              efficient transition and dynamic traction.
            </LI>
          </UL>
        </Content>
        <Header4>Key Comparison</Header4>
        <Content>Endorsed by professional athletes.</Content>
        <Header4>Built with at least 50% Recycled Content</Header4>
        <Header4>Key Audience</Header4>
        <Content>Elite Players, High Performance</Content>
      </div>
    ),
  },
  P1: {
    name: "Predator.1",
    price: "$250",
    image: imageP1,
    content: ({}) => (
      <div>
        <Header4>Fit</Header4>
        <Content $as="div">
          <UL>
            <LI>2-Piece PRIMEKNIT lowcut collar.</LI>
            <LI>Laced model.</LI>
          </UL>
        </Content>
        <Header4>Touch</Header4>
        <Content $as="div">
          <UL>
            <LI>
              High-definition grip technology: individual rubber elements in the
              kicking zone for enhanced grip on the ball while maintaining a
              soft upper feel.
            </LI>
          </UL>
        </Content>
        <Header4>Movement</Header4>
        <Content $as="div">
          <UL>
            <LI>
              A revised FACETFRAME split tooling delivers stability, highly
              efficient transition and dynamic traction.
            </LI>
          </UL>
        </Content>
        <Header4>Key Comparison</Header4>
        <Content>Available in the mid-cut and low-cut.</Content>
        <Header4>Built with at least 50% Recycled Content</Header4>
        <Header4>Key Audience</Header4>
        <Content>Elite Players, High Performance</Content>
      </div>
    ),
  },
  P2: {
    name: "Predator.2",
    price: "$150",
    image: imageP2,
    content: ({}) => (
      <div>
        <Header4>Fit</Header4>
        <Content $as="div">
          <UL>
            <LI>1-Piece PRIMEKNIT collar</LI>
            <LI>
              Contains soft Hybridtouch vamp material for a snug and comfortable
              fit.
            </LI>
          </UL>
        </Content>
        <Header4>Touch</Header4>
        <Content $as="div">
          <UL>
            <LI>
              High-definition grip technology: individual rubber elements in the
              kicking zone for enhanced grip on the ball while maintaining a
              soft upper feel.
            </LI>
          </UL>
        </Content>
        <Header4>Movement</Header4>
        <Content $as="div">
          <UL>
            <LI>
              Facetted TPU tooling with traction studs for maneuverability.
            </LI>
          </UL>
        </Content>
        <Header4>Key Comparison</Header4>
        <Content>Includes PRIMEKNIT.</Content>
        <Header4>Built with at least 50% Recycled Content</Header4>
        <Header4>Key Audience</Header4>
        <Content>Competitive, High School &amp; College Players</Content>
      </div>
    ),
  },
  P3: {
    name: "Predator.3",
    price: "$90",
    image: imageP3,
    content: ({}) => (
      <div>
        <Header4>Fit</Header4>
        <Content $as="div">
          <UL>
            <LI>Laceless id-cut laced model</LI>
            <LI>Uses soft synthetic vamp with a textile collar and binding.</LI>
          </UL>
        </Content>
        <Header4>Touch</Header4>
        <Content $as="div">
          <UL>
            <LI>
              High-definition 3D texture allows for better grip on the ball and
              close boot to ball contact.
            </LI>
          </UL>
        </Content>
        <Header4>Movement</Header4>
        <Content $as="div">
          <UL>
            <LI>
              Facetted TPU tooling with traction studs for maneuverability.
            </LI>
          </UL>
        </Content>
        <Header4>Key Comparison</Header4>
        <Content>Available in mid-cut ($90), and low-cut ($85) models.</Content>
        <Header4>Built with at least 50% Recycled Content</Header4>
        <Header4>Key Audience</Header4>
        <Content>Recreational Players</Content>
      </div>
    ),
  },
  P3L: {
    name: "Predator.3 (Laceless)",
    price: "$100",
    image: imageP3L,
    content: ({}) => (
      <div>
        <Header4>Fit</Header4>
        <Content $as="div">
          <UL>
            <LI>Laceless id-cut laced model</LI>
            <LI>Uses soft synthetic vamp with a textile collar and binding.</LI>
          </UL>
        </Content>
        <Header4>Touch</Header4>
        <Content $as="div">
          <UL>
            <LI>
              High-definition 3D texture allows for better grip on the ball and
              close boot to ball contact.
            </LI>
          </UL>
        </Content>
        <Header4>Movement</Header4>
        <Content $as="div">
          <UL>
            <LI>
              High-definition texture: 3D texture allows for better grip on the
              ball and close boot to ball contact.
            </LI>
          </UL>
        </Content>
        <Header4>Key Comparison</Header4>
        <Content>Available in mid-cut ($90), and low-cut ($85) models.</Content>
        <Header4>Built with at least 50% Recycled Content</Header4>
        <Header4>Key Audience</Header4>
        <Content>Recreational Players</Content>
      </div>
    ),
  },
  P4: {
    name: "Predator.4",
    price: "$65",
    image: imageP4,
    content: ({}) => (
      <div>
        <Header4>Fit</Header4>
        <Content $as="div">
          <UL>
            <LI>Uses a floating tong construction with synthetic vamp.</LI>
          </UL>
        </Content>
        <Header4>Touch</Header4>
        <Content $as="div">
          <UL>
            <LI>
              The coated textile upper provides great performance and
              durability.
            </LI>
          </UL>
        </Content>
        <Header4>Movement</Header4>
        <Content $as="div">
          <UL>
            <LI>Flexible FxG outsole.</LI>
          </UL>
        </Content>
        <Header4>Key Comparison</Header4>
        <Content>Best for value driven players.</Content>
        <Header4>Built with at least 50% Recycled Content</Header4>
        <Header4>Key Audience</Header4>
        <Content>Youth and Value Driven Players</Content>
      </div>
    ),
  },
};

const SectionPredatorAccuracyFranchiseOverview = () => {
  return <FeatureComparison shoes={shoes} primaryColor={"#E3157E"} />;
};

export default SectionPredatorAccuracyFranchiseOverview;
