import * as React from "react";
import { styled } from "styletron-react";

const StyledVideo = styled("video", () => {
  return {
    objectFit: "cover",
    objectPosition: "center",
  };
});

const BackgroundVideo = (...params: Parameters<typeof StyledVideo>) => {
  const [props] = params;
  return <StyledVideo muted autoPlay loop playsInline {...props} />;
};

export default BackgroundVideo;
