import * as React from "react";
import { useContext } from "react";
import { StyleObject, useStyletron } from "styletron-react";
import VideoContext from "../contexts/VideoContext";
import { useTag } from "../hooks/useTag";
import BorderedButton from "./BorderedButton";
import Button from "./Button";

interface IVideoLink {
  children: any;
  $style?: StyleObject;
  href: string;
  variant?: "primary" | "secondary" | "tertiary";
  wrapOnly?: boolean;
  hash?: string;
  component?: typeof Button | typeof BorderedButton;
}

const getVideoId = (url: string) => {
  return url.match(/([\d]+)/g).shift();
};

const VideoLink = ({
  children,
  $style,
  href,
  variant = "primary",
  wrapOnly = false,
  hash = undefined,
  component: Component = Button,
}: IVideoLink) => {
  const [css] = useStyletron();
  const { setVideo } = useContext(VideoContext);
  const videoId = getVideoId(href);
  const { tag } = useTag();

  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    tag("video_play", { id: videoId, url: href });
    setVideo({ id: videoId, url: href, hash });
  };

  return wrapOnly ? (
    <a href={href} onClick={onLinkClick} className={css($style)}>
      {children}
    </a>
  ) : (
    <Component
      $style={$style}
      $as="a"
      href={href}
      variant={variant}
      onClick={onLinkClick}
    >
      {children}
    </Component>
  );
};

export default VideoLink;
