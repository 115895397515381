import React, { useEffect } from "react";
import { useContext, useState, useRef } from "react";
import { StyleObject, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";
import Header3 from "./Header3";

export type ShoeDetails = {
  name: string;
  price: string;
  image: any;
  content: React.FC<{ css: (style: StyleObject) => string }>;
};

export const FeatureComparison: React.FC<{
  shoes: Record<string, ShoeDetails>;
  primaryColor?: string;
  color?: string;
  headerColor?: string;
  activeColor?: string;
}> = ({
  shoes,
  primaryColor = "#E3157EFF",
  color = "#000000FF",
  headerColor = "#000000FF",
  activeColor = "#000000FF",
}) => {
  const shoeTypes = Object.keys(shoes);

  const [css] = useStyletron();
  const theme = useContext(ThemeContext);
  const [selected, setSelected] = useState<string>(shoeTypes[0]);
  const overviewRef = useRef<HTMLDivElement>();

  const selectedShoe = shoes[selected].name;
  const SelectedShoeContent = shoes[selected].content;

  return (
    <div
      className={css({
        width: "100%",
        paddingRight: px(40),
        paddingLeft: px(40),
        display: "flex",
        justifyContent: "center",
      })}
    >
      <div
        className={css({
          width: "100%",
          marginBottom: px(90),
          [theme.MediaQueries.desktop]: {
            display: "none",
          },
        })}
      >
        <Header3
          $style={{
            color: headerColor,
            fontSize: px(47),
            lineHeight: px(47),
            [theme.MediaQueries.desktop]: {
              fontSize: px(70),
              lineHeight: px(70),
            },
          }}
        >
          Franchise Overview
        </Header3>

        <div>
          {shoeTypes.map((shoe) => {
            return (
              <MobileShoe
                key={shoe}
                shoe={shoes[shoe]}
                headerColor={headerColor}
                primaryColor={primaryColor}
                isSelected={shoe === selected}
                shortName={shoe}
                onClick={(e) => {
                  e.preventDefault();
                  setSelected(shoe);
                  if (overviewRef.current) {
                    overviewRef.current.scroll(0, 0);
                  }
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        className={css({
          display: "none",
          width: "100%",
          maxWidth: px(1098),
          [theme.MediaQueries.desktop]: {
            display: "block",
          },
        })}
      >
        <Header3
          $style={{
            color: headerColor,
            fontSize: px(47),
            lineHeight: px(47),
            [theme.MediaQueries.desktop]: {
              fontSize: px(70),
              lineHeight: px(70),
            },
          }}
        >
          Franchise Overview
        </Header3>

        <div
          className={css({
            borderColor: primaryColor,
            borderWidth: px(4),
            borderRadius: px(40),
            width: "100%",
            borderStyle: "solid",
            display: "flex",
            overflow: "hidden",
          })}
        >
          <div
            className={css({
              borderColor: primaryColor,
              borderWidth: px(4),
              borderRightStyle: "solid",
              display: "flex",
              flexDirection: "column",
              width: px(150),
            })}
          >
            {shoeTypes.map((shoe) => (
              <a
                className={css({
                  paddingTop: px(20),
                  paddingBottom: px(20),
                  fontSize: px(70),
                  lineHeight: px(70),
                  fontFamily: "adineue PRO Cond",
                  textTransform: "uppercase",
                  letterSpacing: "0.07em",
                  color: headerColor,
                  textDecoration: "none",
                  borderBottomStyle: "solid",
                  borderColor: primaryColor,
                  borderWidth: px(4),
                  textAlign: "center",
                  ":last-child": {
                    borderBottomStyle: "none",
                  },
                  transition: "color 0.25s, background-color 0.25s",
                  ":hover": {
                    backgroundColor: primaryColor,
                    color: activeColor,
                  },
                  ...(shoe === selected
                    ? {
                        backgroundColor: primaryColor,
                        color: activeColor,
                      }
                    : {}),
                })}
                href="#"
                key={`${shoe}Link`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelected(shoe);
                  if (overviewRef.current) {
                    overviewRef.current.scroll(0, 0);
                  }
                }}
              >
                {shoe}
              </a>
            ))}
          </div>
          <div
            className={css({
              flex: 1,
              display: "inline-flex",
              flexDirection: "row",
              minHeight: px(114 * shoeTypes.length - 4),
              maxHeight: px(114 * shoeTypes.length - 4),
            })}
          >
            <div
              className={css({
                width: "35%",
                alignItems: "center",
                display: "flex",
                marginRight: px(30),
                marginLeft: px(30),
                justifyContent: "center",
              })}
            >
              {shoes[selected].image && (
                <img
                  src={shoes[selected].image}
                  alt={`${selectedShoe} boot`}
                  className={css({
                    width: "80%",
                  })}
                />
              )}
            </div>
            <div
              ref={overviewRef}
              className={css({
                flex: 1,
                overflowY: "auto",
                paddingRight: px(46),
                marginRight: px(4),
                marginBottom: px(4),
                "::-webkit-scrollbar": {
                  width: px(4),
                  height: px(4),
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  padding: px(2),
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: primaryColor,
                  borderRadius: px(4),
                },
              })}
            >
              <Header3
                $style={{
                  display: "flex",
                  marginTop: px(100),
                  color,
                  width: "100%",
                  justifyContent: "flex-start",
                  [theme.MediaQueries.desktop]: {
                    fontSize: px(50),
                    lineHeight: px(50),
                  },
                }}
              >
                <span className={css({ maxWidth: "75%" })}>{selectedShoe}</span>
                <span className={css({ flex: 1 })}></span>
                <span
                  className={css({
                    fontFamily: "AdihausDIN",
                    fontWeight: 500,
                    fontStyle: "normal",
                    textAlign: "right",
                    maxWidth: "50%",
                  })}
                >
                  <span
                    className={css({
                      transform: "scale(80%)",
                      position: "relative",
                      display: "inline-block",
                    })}
                  >
                    {shoes[selected].price}
                  </span>
                </span>
              </Header3>
              <div className={css({ paddingBottom: px(30) })}>
                <SelectedShoeContent css={css} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileShoe: React.FC<{
  shoe: ShoeDetails;
  primaryColor: string;
  headerColor: string;
  isSelected: boolean;
  shortName: string;
  onClick: (e: any) => void;
}> = ({ shoe, primaryColor, isSelected, headerColor, onClick, shortName }) => {
  const [css] = useStyletron();
  const [count, setCount] = useState(0);
  const Content = shoe.content;
  const mobileContentRef = useRef<HTMLDivElement>();

  const getContentHeight = () => {
    return mobileContentRef.current
      ? isSelected
        ? mobileContentRef.current.getBoundingClientRect().height
        : 0
      : 0;
  };

  const [heightOffset, setHeightOffset] = useState(getContentHeight());

  useEffect(() => {
    const listener = () => {
      setHeightOffset(getContentHeight());
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [isSelected]);

  useEffect(() => {
    setHeightOffset(getContentHeight());
  }, [count, isSelected]);

  return (
    <div
      key={`${shoe}Mobile`}
      className={css({
        borderBottomStyle: "solid",
        borderColor: primaryColor,
        borderWidth: px(4),
        height: px(128 + heightOffset),
        overflow: "hidden",
        transition: "height 0.5s",
        ":first-child": {
          borderTopStyle: "solid",
        },
      })}
    >
      <a
        className={css({
          display: "block",
          paddingTop: px(20),
          paddingBottom: px(20),
          fontSize: px(70),
          lineHeight: px(70),
          fontFamily: "adineue PRO Cond",
          textTransform: "uppercase",
          letterSpacing: "0.07em",
          color: headerColor,
          textDecoration: "none",
          textAlign: "left",
          paddingLeft: px(30),
          paddingRight: px(30),
        })}
        href="#"
        onClick={onClick}
      >
        {shortName}
      </a>
      <div
        ref={mobileContentRef}
        className={css({
          paddingBottom: px(20),
          paddingTop: px(20),
          paddingLeft: px(30),
          paddingRight: px(30),
        })}
      >
        {shoe.image && (
          <img
            src={shoe.image}
            alt={`${shoe.name} boot`}
            className={css({
              width: "80%",
              marginBottom: px(30),
              display: "block",
            })}
            onLoad={() => {
              setCount((c) => c + 1);
            }}
          />
        )}

        <Header3
          $style={{
            color: headerColor,
            width: "100%",
            fontSize: px(40),
            lineHeight: px(40),
          }}
        >
          {shoe.name}
        </Header3>
        <Header3
          $style={{
            color: headerColor,
            width: "100%",
            fontSize: px(30),
            lineHeight: px(30),
            fontFamily: "AdihausDIN",
            fontWeight: 500,
            fontStyle: "normal",
            marginBottom: px(30),
          }}
        >
          {shoe.price}
        </Header3>
        <Content css={css} />
      </div>
    </div>
  );
};
