import { opacify } from "polished";
import * as React from "react";
import { useContext } from "react";
import { styled, withWrapper } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

interface IButtonProps {
  openExternally?: boolean;
  variant?: "primary" | "secondary" | "tertiary";
  [key: string]: any;
}

const StyledButton = styled<any, IButtonProps>(
  "button",
  ({ variant = "primary" }) => {
    const theme = useContext(ThemeContext);
    return {
      transition: "color 0.25s",
      display: "inline-flex",
      alignItems: "center",
      paddingLeft: px(18),
      paddingRight: px(18),
      fontWeight: "bold",
      fontSize: px(14),
      lineHeight: px(47),
      height: px(47),
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      cursor: "pointer",
      borderWidth: px(2),
      borderStyle: "solid",
      textDecoration: "none",
      color: opacify(0.5, theme.Button[variant].foregroundColor),
      backgroundColor: theme.Button[variant].backgroundColor,
      borderColor: theme.Button[variant].backgroundColor,
      whiteSpace: "nowrap",
      ":hover": {
        color: theme.Button[variant].hoverColor,
      },
      ":active": {
        textDecoration: "underline",
      },
      [theme.MediaQueries.desktop]: {
        fontSize: px(20),
        lineHeight: px(69),
        paddingLeft: px(28),
        paddingRight: px(28),
        height: px(73),
      },
    };
  }
);

const Button = withWrapper(
  StyledButton,
  (Styled) =>
    ({ openExternally, $as, ...rest }: Partial<IButtonProps>) => {
      const extraProps: any = {};
      if (openExternally && $as === "a") {
        extraProps.target = "_blank";
        extraProps.rel = "noopener noreferrer";
      }
      return <Styled $as={$as} {...rest} {...extraProps} />;
    }
);

export default Button;
