import Retailers from "../types/Retailers";
import Sites from "../types/Sites";

export interface IRetailerOptions {
  showIncentive?: boolean;
  productVideoUrl?: string;
  franchiseVideoUrl?: string;
  surfaceOptionsVideoUrl?: string;
}

const RETAILER_OPTIONS: Record<
  Sites,
  Partial<Record<Retailers, IRetailerOptions>>
> = {
  "4dfwd": {
    "Road Runner Sports": { showIncentive: true },
    "Jack Rabbit": { showIncentive: true },
    "Running Warehouse": {},
    "Marathon Sports": { showIncentive: true },
    Frontrunners: {},
    "Gazelle Sports": { showIncentive: true },
    adidas: {},
    "Dick's Sporting Goods": {},
  },
  "x-speedflow": {
    "Niky's Sports": { showIncentive: true },
    "Upper 90's": { showIncentive: true },
    "Soccer Post": { showIncentive: true },
    adidas: {
      // productVideoUrl: "https://vimeo.com/573213154/fede2d7211",
      // franchiseVideoUrl: "https://vimeo.com/573216927/29068a2d48",
      // surfaceOptionsVideoUrl: "https://vimeo.com/573217056/085b2a744b",
    },
    "Dick's Sporting Goods": {
      // productVideoUrl: "https://vimeo.com/573217093/8a70e1905c",
      // franchiseVideoUrl: "https://vimeo.com/573216836/cd0e008b81",
      // surfaceOptionsVideoUrl: "https://vimeo.com/573217015/e21b0a1105",
    },
  },
  adizero: {
    "Road Runner Sports": { showIncentive: true },
    "Jack Rabbit": { showIncentive: true },
    "Running Warehouse": {},
    "Marathon Sports": { showIncentive: true },
    Pacers: { showIncentive: true },
    "Fleet Feet": {},
    "Gazelle Sports": { showIncentive: true },
    adidas: {},
    "Dick's Sporting Goods": {},
  },
  adisoccer: {},
};

export default RETAILER_OPTIONS;
