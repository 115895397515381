import * as React from "react";
import { useContext } from "react";
import { useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import imageSplash from "../images/adisoccer/splash.3.jpeg";
import Retailers from "../types/Retailers";
import { px } from "../utilities/px";
import Content from "./Content";
import Header1 from "./Header1";
import Logo from "./Logo";
import { PerformanceLogo } from "./PerformanceLogo";
import RetailerSelect from "./RetailerSelect";

interface ISplashScreenProps extends Record<string, any> {
  retailers?: Record<string, Retailers>;
}

const SoccerSplashScreen = ({ retailers = {} }: ISplashScreenProps) => {
  const [css] = useStyletron();
  const theme = useContext(ThemeContext);
  const color = "#FFFFFFFF";
  return (
    <div
      className={css({
        display: "block",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${imageSplash})`,
        backgroundSize: "cover",
        overflow: "hidden",
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        })}
      >
        <div
          className={css({
            minHeight: px(75),
            maxHeight: px(75),
            [theme.MediaQueries.desktop]: {
              minHeight: px(0),
              maxHeight: "100%",
              flex: 1,
            },
          })}
        ></div>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: px(264),
            maxWidth: px(264),
            position: "relative",

            [theme.MediaQueries.desktop]: {
              minWidth: px(400),
              maxWidth: px(400),
            },
          })}
        >
          <PerformanceLogo
            $desktopWidth={90}
            $style={{
              color,
              position: "relative",
              zIndex: 2,
              marginBottom: px(20),
            }}
          />
          <Header1
            $style={{
              color,
              textAlign: "center",
              fontSize: px(30),
              lineHeight: px(34.35),
              marginBottom: px(10),
              textShadow: `${px(0)} ${px(4)} ${px(8)} #E3157E99`,
              position: "relative",
              zIndex: 2,
              [theme.MediaQueries.desktop]: {
                fontSize: px(40),
                lineHeight: px(46),
                marginBottom: px(20),
                textShadow: `${px(0)} ${px(4)} ${px(4)} #E3157E99`,
              },
            }}
          >
            Learn what's new from adidas&nbsp;Soccer.
          </Header1>
          <Content
            $style={{
              color,
              textAlign: "center",
              fontSize: px(20),
              lineHeight: px(25.76),
              marginBottom: px(20),
              textShadow: `${px(0)} ${px(4)} ${px(8)} #E3157E99`,
              position: "relative",
              zIndex: 2,
              [theme.MediaQueries.desktop]: {
                fontSize: px(23),
                lineHeight: px(29.62),
                marginBottom: px(40),
                textShadow: `${px(0)} ${px(4)} ${px(4)} #E3157E99`,
              },
            }}
          >
            Test your knowledge to keep your&nbsp;accuracy.
          </Content>
          <RetailerSelect zIndex={2} color={"white"} retailers={retailers} />
        </div>
        <div className={css({ flex: 1 })}></div>
      </div>
    </div>
  );
};

export default SoccerSplashScreen;
