import { createContext } from "react";
import CONSTANTS from "../utilities/CONSTANTS";
import { px } from "../utilities/px";

export const ThemeContext = createContext({
  Video: {
    primary: CONSTANTS.RED_NO_HASH,
  },
  Button: {
    primary: {
      backgroundColor: "#FFFFFFFF",
      foregroundColor: "#000000FF",
      hoverColor: `#00000099`,
    },
    secondary: {
      backgroundColor: "#000000FF",
      foregroundColor: "#FFFFFFFF",
      hoverColor: `#FFFFFF99`,
    },
    tertiary: {
      backgroundColor: "#E63320FF",
      foregroundColor: "#FFFFFFFF",
      hoverColor: `#FFFFFF99`,
    },
  },
  BorderedButton: {
    primary: {
      backgroundColor: "#FFFFFFFF",
      foregroundColor: "#000000FF",
      hoverColor: `#00000099`,
    },
    secondary: {
      backgroundColor: "#000000FF",
      foregroundColor: "#FFFFFFFF",
      hoverColor: `#FFFFFF99`,
    },
    tertiary: {
      backgroundColor: "#FFFFFFFF",
      foregroundColor: "#E63320FF",
      hoverColor: `#E6332099`,
    },
  },
  MediaQueries: {
    desktop: `@media screen and (min-width: ${px(880)})`,
  },
});
