import * as React from "react";
import { useContext } from "react";
import { styled, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import SearchIcon from "../images/search.svg";
import { px } from "../utilities/px";

const ratio = 1;

const SearchIconContain = styled("div", () => {
  const theme = useContext(ThemeContext);
  return {
    width: px(20 * ratio),
    height: px(20),
    lineHeight: 0,
    [theme.MediaQueries.desktop]: {
      width: px(24 * ratio),
      height: px(24),
    },
  };
});

const Search = (...params: Parameters<typeof SearchIconContain>) => {
  const [css] = useStyletron();
  const [props] = params;
  return (
    <SearchIconContain {...props}>
      <SearchIcon className={css({ width: "100%", height: "100%" })} />
    </SearchIconContain>
  );
};

export default Search;
