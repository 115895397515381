import * as React from "react";
import { useContext } from "react";
import { styled, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import PlayIcon from "../images/play.svg";
import { px } from "../utilities/px";

const ratio = 18 / 19;

const PlayIconContain = styled("div", () => {
  const theme = useContext(ThemeContext);
  return {
    minWidth: px(18 * ratio),
    width: px(18 * ratio),
    height: px(18),
    marginLeft: px(15),
    lineHeight: 0,
    [theme.MediaQueries.desktop]: {
      minWidth: px(32 * ratio),
      width: px(32 * ratio),
      height: px(32),
    },
  };
});

const Play = (...params: Parameters<typeof PlayIconContain>) => {
  const [css] = useStyletron();
  const [props] = params;
  return (
    <PlayIconContain {...props}>
      <PlayIcon className={css({ width: "100%", height: "100%" })} />
    </PlayIconContain>
  );
};

export default Play;
