import * as React from "react";
import { useContext } from "react";
import { styled } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const StyledContent = styled<"p", { noOverride?: boolean }>("p", () => {
  const theme = useContext(ThemeContext);
  return {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: px(16),
    lineHeight: px(17.6),
    letterSpacing: "0.07em",
    marginTop: 0,
    [theme.MediaQueries.desktop]: {
      fontSize: px(20),
      lineHeight: px(22),
    },
  };
});

const Content = (...params: Parameters<typeof StyledContent>) => {
  const [{ noOverride = false, ...props }] = params;

  const overrides = { children: props.children };
  if (!noOverride && typeof props.children === "string") {
    const content = props.children.trim();
    const lastSpaceIndex = content.lastIndexOf(" ");
    overrides.children = `${content.substr(
      0,
      lastSpaceIndex
    )}\xA0${content.substr(lastSpaceIndex + 1)}`;
  }

  const newProps = { ...props, ...overrides };
  return <StyledContent {...newProps} />;
};

export default Content;
