import { Link } from "gatsby";
import * as React from "react";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { styled, useStyletron } from "styletron-react";
import Logo from "../components/Logo";
import { ThemeContext } from "../contexts/ThemeContext";
import Close from "../images/close.svg";
import Hamburger from "../images/hamburger.svg";
import { getSoccerBasePath } from "../utilities/getSoccerBasePath";
import { px } from "../utilities/px";

const MenuHeader = styled(Link, () => {
  const theme = useContext(ThemeContext);
  return {
    fontFamily: "adineue PRO Cond",
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    marginTop: 0,
    marginBottom: 0,
    fontSize: px(20),
    lineHeight: px(14.5),
    color: "#000000FF",
    textDecoration: "none",
    whiteSpace: "nowrap",

    [theme.MediaQueries.desktop]: {
      fontSize: px(40),
      lineHeight: px(29),
      marginLeft: px(30),
    },
  };
});

const MenuLink = styled(
  Link,
  ({
    color = "#000000FF",
    $activeColor = "#00000099",
  }: {
    color?: string;
    $activeColor?: string;
  }) => {
    const theme = useContext(ThemeContext);
    return {
      display: "block",
      fontFamily: "adineue PRO Cond",
      textTransform: "uppercase",
      letterSpacing: "0.07em",
      marginTop: 0,
      fontSize: px(42),
      lineHeight: px(72),
      color,
      textDecoration: "none",
      whiteSpace: "nowrap",
      marginBottom: px(10),
      textAlign: "center",
      ":hover": {
        textDecoration: "underline",
      },
      ":active": {
        color: $activeColor,
      },
      ":last-of-type": {
        marginBottom: 0,
      },
      [theme.MediaQueries.desktop]: {
        fontSize: px(22),
        lineHeight: px(17),
        marginBottom: 0,
      },
    };
  }
);

type MenuLink = { url: string; title: string };
type MenuLinks = MenuLink[];

type MenuAlign = { desktop: string };
type MenuHeight = { mobile: string; desktop: string };
type ShowHomeLink = { mobile: boolean; desktop: boolean };

type MenuProps = {
  links?: MenuLinks;
  baseHref?: string;
  height?: MenuHeight;
  align?: MenuAlign;
  showHomeLink?: ShowHomeLink;
  homeLink: MenuLink;
  backgroundColor?: string;
  overlayBackground?: Record<string, string>;
  foregroundColor?: string;
  logoColor?: string;
  logo?: typeof Logo;
};

import AdidasLogo from "../images/adidas.svg";

const MenuLogo: typeof Logo = (props) => {
  return <Logo {...props} $desktopWidth={63} $width={30} logo={AdidasLogo} />;
};

const Menu = ({
  links = [],
  baseHref = "/",
  align = { desktop: "flex-end" },
  height = { mobile: px(38), desktop: px(125) },
  showHomeLink = { mobile: true, desktop: true },
  backgroundColor = "#FFFFFFFF",
  overlayBackground = {},
  foregroundColor = "#000000FF",
  logoColor = "#000000FF",
  logo = MenuLogo,
  homeLink,
}: MenuProps) => {
  const [css] = useStyletron();
  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const LocalLogo = logo;

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={css({ position: "relative", zIndex: 3 })}>
      <Helmet
        bodyAttributes={{
          ["data-menu"]: isOpen ? "open" : "closed",
        }}
      >
        <base href={baseHref} />
      </Helmet>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          height: height.mobile,
          width: "100%",
          position: "relative",
          zIndex: 2,
          backgroundColor,
          [theme.MediaQueries.desktop]: {
            height: height.desktop,
          },
        })}
      >
        <div className={css({ flex: 1 })} />
        <div className={css({ display: "flex", flexDirection: "row" })}>
          <div className={css({ flex: 1 })} />
          <div
            className={css({
              display: "flex",
              flexDirection: "row",
              width: "100%",
              minWidth: px(320),
              maxWidth: px(540),
              paddingLeft: px(20),
              paddingRight: px(20),
              alignItems: "center",
              [theme.MediaQueries.desktop]: {
                width: "100%",
                maxWidth: px(1090),
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: px(40),
                marginRight: px(40),
                alignItems: align.desktop,
              },
            })}
          >
            <Link to={`${baseHref}`} className={css({ color: logoColor })}>
              <LocalLogo title={homeLink.title} />
            </Link>
            <div
              className={css({
                flex: 1,
                textAlign: "center",
                [theme.MediaQueries.desktop]: {
                  flex: 0,
                },
              })}
            >
              {showHomeLink.desktop ? (
                <MenuHeader to={`${baseHref}`}>adidas adizero</MenuHeader>
              ) : undefined}
            </div>

            <div
              className={css({
                display: "none",
                [theme.MediaQueries.desktop]: {
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                },
              })}
            >
              {links.map((link, i) => {
                return (
                  <MenuLink
                    color={foregroundColor}
                    $activeColor={foregroundColor}
                    key={link.url}
                    to={`${baseHref}${link.url}`}
                    $style={
                      i !== links.length - 1 ? { marginRight: px(60) } : {}
                    }
                  >
                    {link.title}
                  </MenuLink>
                );
              })}
            </div>
            <div
              className={css({
                display: "flex",
                [theme.MediaQueries.desktop]: {
                  display: "none",
                },
              })}
            >
              <div className={css({ width: px(31), textAlign: "center" })}>
                <div
                  className={css({
                    display: "inline-block",
                    cursor: "pointer",
                    color: logoColor,
                  })}
                >
                  {isOpen ? (
                    <Close onClick={onClick} />
                  ) : (
                    <Hamburger onClick={onClick} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={css({ flex: 1 })} />
        </div>
        <div className={css({ flex: 1 })} />
      </div>
      <div
        className={css({
          position: "absolute",
          overflow: "hidden",
          height: "100vh",
          transition: "top 0.25s cubic-bezier(0.45, 0, 0.55, 1)",
          backgroundColor,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          [theme.MediaQueries.desktop]: { display: "none" },
          top: isOpen ? 0 : "-100vh",
          left: 0,
          zIndex: 1,
          ...overlayBackground,
        })}
      >
        <div className={css({ flex: 1 })} />
        {showHomeLink.mobile ? (
          <MenuLink
            to={`${baseHref}`}
            color={foregroundColor}
            $activeColor={foregroundColor}
            id={homeLink.url}
          >
            {homeLink.title}
          </MenuLink>
        ) : undefined}
        {links.map((link) => {
          return (
            <MenuLink
              color={foregroundColor}
              $activeColor={foregroundColor}
              key={link.url}
              to={`${baseHref}${link.url}`}
            >
              {link.title}
            </MenuLink>
          );
        })}
        <div className={css({ flex: 1 })} />
      </div>
    </div>
  );
};

export default Menu;
