import Retailers from "../types/Retailers";
import Sites from "../types/Sites";

const dsg: Record<string, Retailers> = {
  dsg: "Dick's Sporting Goods",
  dick: "Dick's Sporting Goods",
  dicks: "Dick's Sporting Goods",
  ducks: "Dick's Sporting Goods",
  dicksportinggood: "Dick's Sporting Goods",
  dickssportinggood: "Dick's Sporting Goods",
  dickssportinggoods: "Dick's Sporting Goods",
};

const dsgsoccer: Record<string, Retailers> = {
  dsgsoccer: "DSG Soccer Attack",
  dicksoccer: "DSG Soccer Attack",
  dickssoccer: "DSG Soccer Attack",
  duckssoccer: "DSG Soccer Attack",
  dicksportinggoodsoccer: "DSG Soccer Attack",
  dickssportinggoodsoccer: "DSG Soccer Attack",
  dickssportinggoodssoccer: "DSG Soccer Attack",
  dsgsoccerattack: "DSG Soccer Attack",
  dicksoccerattack: "DSG Soccer Attack",
  dickssoccerattack: "DSG Soccer Attack",
  duckssoccerattack: "DSG Soccer Attack",
  dicksportinggoodsoccerattack: "DSG Soccer Attack",
  dickssportinggoodsoccerattack: "DSG Soccer Attack",
  dickssportinggoodssoccerattack: "DSG Soccer Attack",
};

const dtc: Record<string, Retailers> = {
  dtc: "adidas",
  adidas: "adidas",
  adi: "adidas",
  bcs: "adidas",
  adidasoutlet: "adidas",
  adioutlet: "adidas",
  outlet: "adidas",
};

const gazelle: Record<string, Retailers> = {
  gazellesport: "Gazelle Sports",
  gazellesports: "Gazelle Sports",
  gazelle: "Gazelle Sports",
  gazelles: "Gazelle Sports",
  gs: "Gazelle Sports",
};

const frontrunners: Record<string, Retailers> = {
  frontrunner: "Frontrunners",
  frontrunners: "Frontrunners",
  fr: "Frontrunners",
};

const marathonsports: Record<string, Retailers> = {
  marathon: "Marathon Sports",
  marathonsport: "Marathon Sports",
  marathonsports: "Marathon Sports",
  ms: "Marathon Sports",
};

const runningwarehouse: Record<string, Retailers> = {
  runningwarehouse: "Running Warehouse",
  runwarehouse: "Running Warehouse",
  rw: "Running Warehouse",
};

const jackrabbit: Record<string, Retailers> = {
  jack: "Jack Rabbit",
  jackrabbit: "Jack Rabbit",
  jr: "Jack Rabbit",
  rabbit: "Jack Rabbit",
};

const roadrunnersports: Record<string, Retailers> = {
  road: "Road Runner Sports",
  roadrunner: "Road Runner Sports",
  roadrunnersport: "Road Runner Sports",
  roadrunnersports: "Road Runner Sports",
  rrs: "Road Runner Sports",
};

const pacers: Record<string, Retailers> = {
  pacers: "Pacers",
  pacer: "Pacers",
  pace: "Pacers",
};

const fleetfeet: Record<string, Retailers> = {
  fleetfeet: "Fleet Feet",
  ff: "Fleet Feet",
  fleet: "Fleet Feet",
  feet: "Fleet Feet",
};

const nikys: Record<string, Retailers> = {
  nicky: "Niky's Sports",
  nickys: "Niky's Sports",
  nickysport: "Niky's Sports",
  nickysports: "Niky's Sports",
  nickyssports: "Niky's Sports",
  niky: "Niky's Sports",
  nikys: "Niky's Sports",
  nikysport: "Niky's Sports",
  nikysports: "Niky's Sports",
  nikyssports: "Niky's Sports",
  nikes: "Niky's Sports",
  nikos: "Niky's Sports",
};

const soccerpost: Record<string, Retailers> = {
  post: "Soccer Post",
  soccerpost: "Soccer Post",
  socerpost: "Soccer Post",
};

const upper90s: Record<string, Retailers> = {
  up90: "Upper 90's",
  uper90: "Upper 90's",
  uper90s: "Upper 90's",
  upper90: "Upper 90's",
  upper90s: "Upper 90's",
};

const angelos: Record<string, Retailers> = {
  angelos: "Angelos",
  angelo: "Angelos",
};

const rockvillesoccer: Record<string, Retailers> = {
  rockvillesoccer: "Rockville Soccer",
  rockville: "Rockville Soccer",
  rsoccer: "Rockville Soccer",
  rocksoccer: "Rockville Soccer",
  rvillesoccer: "Rockville Soccer",
};

const springfieldsoccersupplies: Record<string, Retailers> = {
  springfieldsoccersupplies: "Springfield Soccer Supplies",
  springfieldsoccersupply: "Springfield Soccer Supplies",
  springfieldsoccer: "Springfield Soccer Supplies",
  springfieldsupplies: "Springfield Soccer Supplies",
  springfieldsupply: "Springfield Soccer Supplies",
  sss: "Springfield Soccer Supplies",
  sfs: "Springfield Soccer Supplies",
};

const prosoccer: Record<string, Retailers> = {
  prosoccer: "Pro Soccer",
  psoccer: "Pro Soccer",
};

const xtremesoccer: Record<string, Retailers> = {
  xtremesoccer: "Xtreme Soccer",
  xsoccer: "Xtreme Soccer",
  xtreme: "Xtreme Soccer",
};

const internationalsoccerandrugby: Record<string, Retailers> = {
  internationalsoccerandrugby: "International Soccer & Rugby",
  internationalsoccerrugby: "International Soccer & Rugby",
  soccerandrugby: "International Soccer & Rugby",
  internationalsoccer: "International Soccer & Rugby",
  internationalrugby: "International Soccer & Rugby",
};

const soccerzone: Record<string, Retailers> = {
  soccerzone: "Soccer Zone",
  zone: "Soccer Zone",
};

const pelesoccer: Record<string, Retailers> = {
  pelesoccer: "Pele Soccer",
  pele: "Pele Soccer",
  peles: "Pele Soccer",
};

const soccerloco: Record<string, Retailers> = {
  soccerloco: "Soccerloco",
  loco: "Soccerloco",
};

const soccerpro: Record<string, Retailers> = {
  soccerpro: "Soccer Pro",
  soccerp: "Soccer Pro",
};

const wegotsoccer: Record<string, Retailers> = {
  wegotsoccer: "WeGotSoccer",
  wgs: "WeGotSoccer",
  wegot: "WeGotSoccer",
  gotsoccer: "WeGotSoccer",
  wesoccer: "WeGotSoccer",
};

const RETAILER_LOOKUP: Record<Sites, Record<string, Retailers>> = {
  "4dfwd": {
    ...roadrunnersports,
    ...jackrabbit,
    ...runningwarehouse,
    ...marathonsports,
    ...frontrunners,
    ...gazelle,
    ...dtc,
    ...dsg,
  },
  "x-speedflow": {
    ...nikys,
    ...upper90s,
    ...soccerpost,
    ...dtc,
    ...dsg,
  },
  "x-speedportal": {
    ...nikys,
    ...upper90s,
    ...soccerpost,
    ...dtc,
    ...dsg,
  },
  adizero: {
    ...fleetfeet,
    ...pacers,
    ...roadrunnersports,
    ...jackrabbit,
    ...runningwarehouse,
    ...marathonsports,
    ...gazelle,
    ...dtc,
    ...dsg,
  },
  adisoccer: {
    ...dtc,
    ...angelos,
    ...dsg,
    ...dsgsoccer,
    ...rockvillesoccer,
    ...soccerpost,
    ...springfieldsoccersupplies,
    ...nikys,
    ...prosoccer,
    ...xtremesoccer,
    ...internationalsoccerandrugby,
    ...soccerzone,
    ...pelesoccer,
    ...upper90s,
    ...wegotsoccer,
    ...soccerloco,
    ...soccerpro,
  },
};

export default RETAILER_LOOKUP;
