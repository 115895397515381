import * as React from "react";
import { Helmet } from "react-helmet";
import GateKeeper from "../../components/GateKeeper";
import Page from "../../components/Page";
import SoccerSplashScreen from "../../components/SoccerSplashScreen";
import ContentAdiSoccerPredatorAccuracy from "../../content/ContentAdiSoccerPredatorAccuracy";
import ContentAdiSoccerPredatorEdge from "../../content/ContentAdiSoccerPredatorEdge";
import RETAILER_LOOKUP from "../../utilities/RETAILER_LOOKUP";
import PageXSpeedPortal from "../x-speedportal";

const PageAdiSoccer = () => {
  const title = "Adidas Soccer";
  return (
    <Page title={title}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GateKeeper
        gate={<SoccerSplashScreen retailers={RETAILER_LOOKUP["adisoccer"]} />}
        garden={<ContentAdiSoccerPredatorAccuracy />}
      />
    </Page>
  );
};

export default PageAdiSoccer;
