import tag from "../utilities/tag";

export function useTag() {
  const doTag = (event: string, data: Record<string, any>) => {
    return tag(event, { ...data });
  };

  return {
    tag: doTag,
  };
}
