import { Link as GatsbyLink } from "gatsby";
import { useContext } from "react";
import { styled } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const Link = styled(GatsbyLink, () => {
  const theme = useContext(ThemeContext);
  return {
    display: "inline-flex",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: px(16),
    lineHeight: px(17.6),
    letterSpacing: "0.07em",
    whiteSpace: "nowrap",
    textDecoration: "none",
    [theme.MediaQueries.desktop]: {
      fontSize: px(20),
      lineHeight: px(22),
    },
    ":hover": {
      textDecoration: "underline",
    },
    ":active": {
      textDecoration: "underline",
    },
    [theme.MediaQueries.desktop]: {},
  };
});

export default Link;
