import * as React from "react";
import Logo from "../components/Logo";
import { PerformanceLogo } from "../components/PerformanceLogo";
import { getSoccerBasePath } from "../utilities/getSoccerBasePath";
import { px } from "../utilities/px";
import Menu from "./Menu";

const MenuAdiSoccer = (props: {
  backgroundColor?: string;
  foregroundColor?: string;
  logoColor?: string;
  overlayBackground?: Record<string, string>;
  logo?: typeof Logo;
}) => {
  return (
    <Menu
      {...props}
      baseHref={getSoccerBasePath()}
      homeLink={{ url: "", title: "" }}
      showHomeLink={{ mobile: false, desktop: false }}
      height={{ mobile: px(38), desktop: px(94) }}
      align={{ desktop: "center" }}
      logo={PerformanceLogo}
      links={[
        { url: "predator-accuracy/", title: "Predator Accuracy" },
        { url: "x-speedportal/", title: "X Speedportal" },
        { url: "copa/", title: "Copa Pure" },
      ]}
    />
  );
};

export default MenuAdiSoccer;
