import { useContext } from "react";
import { withStyleDeep } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import Button from "./Button";

const BorderedButton = withStyleDeep<
  typeof Button,
  { variant?: "primary" | "secondary" | "tertiary" }
>(Button, ({ variant = "primary" }) => {
  const theme = useContext(ThemeContext);
  return {
    color: theme.BorderedButton[variant].foregroundColor,
    backgroundColor: theme.BorderedButton[variant].backgroundColor,
    borderColor: theme.BorderedButton[variant].foregroundColor,
    ":hover": {
      color: theme.BorderedButton[variant].hoverColor,
    },
  };
});

export default BorderedButton;
