import * as React from "react";
import AdidasLogo from "../images/adidas-performance.svg";
import Logo from "./Logo";

export const PerformanceLogo: typeof Logo = (props) => {
  const desktopWidth = props.$desktopWidth ?? 63;
  const width = props.$width ?? 30;
  return (
    <Logo
      {...props}
      $desktopWidth={desktopWidth}
      $width={width}
      logo={AdidasLogo}
    />
  );
};

PerformanceLogo.RATIO = 228.99 / 364.68;
