import { useContext } from "react";
import { styled } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const Header1 = styled("h1", (props) => {
  const theme = useContext(ThemeContext);

  return {
    fontFamily: "adineue PRO Cond",
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    marginTop: 0,
    marginBottom: px(40),
    fontSize: px(57),
    lineHeight: px(51.3),
    [theme.MediaQueries.desktop]: {
      fontSize: px(80),
      lineHeight: px(72),
    },
  };
});

export default Header1;
