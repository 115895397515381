import * as React from "react";
import { useContext } from "react";
import { styled, StyleObject, useStyletron } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const StyledInput = styled("input", () => {
  const theme = useContext(ThemeContext);

  const fontStyles: Record<string, StyleObject> = {
    mobile: {
      fontSize: px(14),
      lineHeight: px(47),
      fontWeight: 700,
      "::placeholder": {
        fontWeight: 400,
        opacity: 0.5,
        color: "inherit",
      },
    },
    desktop: {
      fontSize: px(20),
      lineHeight: px(69),
    },
  };

  return {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    borderWidth: 0,
    height: px(51),
    letterSpacing: "0.05em",
    color: "inherit",
    textDecoration: "none",
    whiteSpace: "nowrap",
    outline: "none",
    outlineWidth: 0,
    boxShadow: "none",
    textTransform: "uppercase",
    ...fontStyles.mobile,
    ["&:focus"]: { outline: "none", outlineWidth: 0 },
    [theme.MediaQueries.desktop]: {
      ...fontStyles.desktop,
    },
  };
});

const Input = (...params: any) => {
  const theme = useContext(ThemeContext);
  const [{ icon, color, borderColor = color, ...props }] = params;
  const [css] = useStyletron();
  return (
    <div
      className={css({
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        paddingLeft: px(20),
        paddingRight: px(20),
        borderWidth: px(2),
        borderStyle: "solid",
        backgroundColor: color,
        borderColor: borderColor,
        color: color === "white" ? "black" : "white",
        [theme.MediaQueries.desktop]: {
          fontSize: px(20),
          lineHeight: px(69),
          paddingLeft: px(28),
          paddingRight: px(28),
          height: px(73),
          borderWidth: px(4),
        },
      })}
    >
      <StyledInput
        {...props}
        $style={{ backgroundColor: color, borderColor: color }}
      />
      {icon ? <div className={css({})}>{icon}</div> : undefined}
    </div>
  );
};

export default Input;
