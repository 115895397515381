import { useContext } from "react";
import { styled } from "styletron-react";
import { ThemeContext } from "../contexts/ThemeContext";
import { px } from "../utilities/px";

const Header2 = styled("h2", (props) => {
  const theme = useContext(ThemeContext);

  return {
    fontFamily: "adineue PRO Cond",
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    marginTop: 0,
    marginBottom: 0,
    fontSize: px(36),
    lineHeight: px(36),
    [theme.MediaQueries.desktop]: {
      fontSize: px(40),
      lineHeight: px(40),
    },
  };
});

export default Header2;
