import { withAssetPrefix } from "gatsby";
import * as React from "react";
import { useContext } from "react";
import { useStyletron } from "styletron-react";
import BackgroundVideo from "../components/BackgroundVideo";
import BorderedButton from "../components/BorderedButton";
import Button from "../components/Button";
import Content from "../components/Content";
import DownArrow from "../components/DownArrow";
import Header1 from "../components/Header1";
import Header2 from "../components/Header2";
import Header3 from "../components/Header3";
import Link from "../components/Link";
import Play from "../components/Play";
import RightArrow from "../components/RightArrow";
import TechSection from "../components/TechSection";
import VideoLink from "../components/VideoLink";
import { ShareContext } from "../contexts/ShareContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { useRetailer } from "../hooks/useRetailer";
import { useTag } from "../hooks/useTag";
import imageCopa from "../images/adisoccer/copa.3.png";
import imageHero from "../images/adisoccer/copa/hero.3.jpeg";
import imageFacetFrame from "../images/adisoccer/predator-accuracy/facet-frame.4.jpeg";
import imageHighDefinitionGrip from "../images/adisoccer/predator-accuracy/high-definition-grip.4.jpeg";
import imageHybridTouch from "../images/adisoccer/predator-accuracy/hybrid-touch.4.jpeg";
import imageInclusiveByDesign from "../images/adisoccer/predator-accuracy/inclusive-by-design.4.jpeg";
import imagePrimeknit from "../images/adisoccer/predator-accuracy/primeknit.4.jpeg";
import imageSurfaceOptions from "../images/adisoccer/predator-accuracy/surface-options.4.jpeg";
import imageXSpeedportal from "../images/adisoccer/x-speedportal.3.png";
import { getSoccerBasePath } from "../utilities/getSoccerBasePath";
import { px } from "../utilities/px";
import RETAILER_OPTIONS, {
  IRetailerOptions,
} from "../utilities/RETAILER_OPTIONS";
import videoHero from "../videos/predator/hero-accuracy.2.mp4";
import MenuAdiSoccer from "./MenuAdiSoccer";
import SectionPredatorAccuracyFranchiseOverview from "./SectionPredatorAccuracyFranchiseOverview";

const SURVEY_LINK = "https://archrival.typeform.com/to/Niy1cZss";

const ContentAdiSoccerPredatorAccuracy = () => {
  const [css] = useStyletron();
  const theme = useContext(ThemeContext);
  const { triggerShare } = useContext(ShareContext);
  const { tag } = useTag();
  const { retailer } = useRetailer();
  const options: IRetailerOptions =
    RETAILER_OPTIONS["adisoccer"][retailer] ?? {};

  return (
    <>
      <MenuAdiSoccer />
      <header>
        <section
          className={css({
            backgroundImage: `url(${imageHero})`,
            backgroundSize: "cover",
            backgroundPositionY: `top`,
            backgroundPositionX: "left",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            color: "#FFFFFF",
          })}
        >
          <div
            className={css({
              width: "100%",
              maxWidth: px(540),
              position: "relative",
              marginBottom: px(60),
              [theme.MediaQueries.desktop]: {
                maxWidth: px(1440),
                height: px(1128),
                marginBottom: 0,
              },
            })}
          >
            <BackgroundVideo
              className={css({
                display: "none",
                position: "absolute",
                right: 0,
                zIndex: 1,
                [theme.MediaQueries.desktop]: {
                  display: "block",
                  width: px(705),
                  height: px(1128),
                },
              })}
              src={videoHero}
            />
            <div
              className={css({
                position: "relative",
                zIndex: 2,
                width: px(320),
                fontWeight: 700,
                [theme.MediaQueries.desktop]: {
                  width: "100%",
                  display: "flex",
                },
              })}
            >
              <div className={css({ flex: 1 })} />
              <div
                className={css({
                  position: "relative",
                  zIndex: 2,
                  width: px(240),
                  marginLeft: px(40),
                  marginRight: px(40),
                  marginTop: px(45),
                  fontWeight: 700,
                  [theme.MediaQueries.desktop]: {
                    marginLeft: px(40),
                    marginRight: px(40),
                    width: px(1090),
                  },
                })}
              >
                <div
                  className={css({
                    [theme.MediaQueries.desktop]: {
                      marginTop: px(160),
                      width: px(530),
                    },
                  })}
                >
                  <Header1
                    $style={{
                      marginBottom: px(20),
                      textShadow: "2px 2px 1px rgba(0, 0, 0, 0.8)",
                      fontSize: px(48),
                      lineHeight: px(48 * (51.3 / 57)),
                      [theme.MediaQueries.desktop]: { textShadow: "none" },
                    }}
                  >
                    adidas <br />
                    Predator <br />
                    Accuracy
                  </Header1>
                  <Header2
                    $style={{
                      marginBottom: px(30),
                      textShadow: "2px 2px 1px rgba(0, 0, 0, 0.8)",
                      fontSize: px(36 * (48 / 57)),
                      lineHeight: px(36 * (48 / 57)),
                      [theme.MediaQueries.desktop]: { textShadow: "none" },
                    }}
                  >
                    Miss Nothing
                  </Header2>
                  <BackgroundVideo
                    className={css({
                      display: "block",
                      width: px(287),
                      height: px(387),
                      marginBottom: px(20),
                      [theme.MediaQueries.desktop]: {
                        display: "none",
                      },
                    })}
                    src={videoHero}
                  />

                  <Content
                    $style={{
                      fontSize: px(18),
                      lineHeight: px(21.6),
                      marginBottom: px(30),
                      textShadow: "1px 1px 1px rgba(0, 0, 0, 0.8)",

                      [theme.MediaQueries.desktop]: {
                        fontSize: px(22),
                        lineHeight: px(26.4),
                        textShadow: "none",
                      },
                    }}
                  >
                    There&rsquo;s accuracy, and there&rsquo;s Predator Accuracy.
                    This is your direct line to the top corner. Boots that back
                    you every time you step up and take a shot. Shoot more. Miss
                    nothing.
                  </Content>
                  <VideoLink
                    $style={{
                      marginBottom: px(30),
                      [theme.MediaQueries.desktop]: {},
                    }}
                    hash="c2e9e49d76"
                    href={
                      options.productVideoUrl ??
                      "https://vimeo.com/786736598/c2e9e49d76"
                    }
                  >
                    Product Overview
                    <Play />
                  </VideoLink>
                  <DownArrow />
                </div>
              </div>
              <div className={css({ flex: 1 })} />
            </div>
          </div>
        </section>
      </header>
      <main
        className={css({
          display: "flex",
          flexDirection: "column",
          position: "relative",
          zIndex: 1,
        })}
      >
        <section
          className={css({
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            maxWidth: px(460),
            paddingTop: px(68),
            [theme.MediaQueries.desktop]: {
              width: "100%",
              maxWidth: px(960),
              paddingTop: px(240),
              paddingLeft: px(40),
              paddingRight: px(40),
            },
          })}
        >
          <TechSection pull="left" image={imagePrimeknit}>
            <Header3 $style={{ position: "relative" }}>
              2 Piece Primeknit Collar Skin
            </Header3>
            <Content $style={{ color: "#898989" }}>
              Constructed 2 Piece Primeknit collar gives serious support while
              staying fully flexible. There&rsquo;s comfort in being clinical.
            </Content>
          </TechSection>
          <TechSection pull="right" image={imageHybridTouch}>
            <Header3 $style={{ position: "relative" }}>
              Hybrid Touch Upper
            </Header3>
            <Content $style={{ color: "#898989" }}>
              Soft Hybrid Touch vamp material for a more moulded fit, weight
              reduction and a soft touch on the ball.
            </Content>
          </TechSection>
          <TechSection pull="left" image={imageHighDefinitionGrip}>
            <Header3 $style={{ position: "relative" }}>
              High Definition Grip
            </Header3>
            <Content $style={{ color: "#898989" }}>
              High-Definition Grip is precisely placed along the upper, adding
              the finishing touch to your finish.
            </Content>
          </TechSection>
          <TechSection pull="right" image={imageFacetFrame}>
            <Header3 $style={{ position: "relative" }}>Facet Frame</Header3>
            <Content $style={{ color: "#898989" }}>
              Delivering stability and dynamic traction to support movement on
              pitch. Power facet redistributes weight towards the front of the
              boot, optimising power transfer when striking the ball.
            </Content>
          </TechSection>
          <TechSection pull="left" image={imageInclusiveByDesign}>
            <Header3 $style={{ position: "relative" }}>
              Inclusive By Design
            </Header3>
            <Content $style={{ color: "#898989" }}>
              Designed for all, available for all, a wider selection of models
              and sizes at all price points offers a more inclusive buying
              experience for everyone.
            </Content>
          </TechSection>
        </section>
        <section
          className={css({
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            maxWidth: px(460),
            [theme.MediaQueries.desktop]: {
              width: "100%",
              minWidth: px(1250),
              maxWidth: px(1440),
              paddingRight: px(40),
              paddingTop: px(50),
              paddingBottom: px(0),
              alignSelf: "flex-end",
            },
            [`@media screen and (min-width: ${px(1440)})`]: {
              alignSelf: "center",
            },
          })}
        >
          {/* <TechSection pull="left" image={imageFranchiseOverview}>
            <Header3>Franchise Overview</Header3>
            <Content $style={{ color: "#898989" }}>
              Predator is a franchise born from mind-blowing free kick finesse
              and unstoppable goalscoring. Some of football&rsquo;s biggest
              names defined football history with match-winning goals in
              predator football boots.
            </Content>
            <VideoLink
              $style={{
                color: "#000000FF",
                borderColor: "#000000FF",
                ":hover": {
                  color: "#00000099",
                },
              }}
              component={BorderedButton}
              hash="b4beab55e0"
              href={
                options?.franchiseVideoUrl ??
                "https://vimeo.com/673284680/2b90168cf4"
              }
            >
              Franchise Overview
              <Play />
            </VideoLink>
          </TechSection> */}
          <TechSection
            pull="right"
            image={imageSurfaceOptions}
            imageStyles={{
              width: "100%",
              [theme.MediaQueries.desktop]: { maxWidth: px(775) },
            }}
          >
            <Header3>Surface Options</Header3>
            <Content $style={{ color: "#898989" }}>
              Various outsoles for every surface boost grip, traction, speed and
              agility so players can take control of the game.
            </Content>
            <VideoLink
              $style={{
                color: "#000000FF",
                borderColor: "#000000FF",
                ":hover": {
                  color: "#00000099",
                },
              }}
              component={BorderedButton}
              hash="6d9046b708"
              href={
                options?.surfaceOptionsVideoUrl ??
                "https://vimeo.com/786757844/6d9046b708"
              }
            >
              Surface Options
              <Play />
            </VideoLink>
          </TechSection>
        </section>
        <section
          className={css({
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            maxWidth: px(460),
            [theme.MediaQueries.desktop]: {
              width: "100%",
              maxWidth: px(1440),
              paddingTop: px(10),
              paddingBottom: px(110),
              alignSelf: "flex-end",
            },
            [`@media screen and (min-width: ${px(1440)})`]: {
              alignSelf: "center",
            },
          })}
        >
          <SectionPredatorAccuracyFranchiseOverview />
        </section>
        <section
          className={css({
            color: "#FFFFFF",
            backgroundColor: "#000000",
            display: "flex",
            flexDirection: "column",
          })}
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              color: "#FFFFFF",
              backgroundColor: "#000000",
              width: "100%",
              minWidth: 0,
              paddingTop: px(53),
              paddingBottom: px(53),
              paddingLeft: px(40),
              paddingRight: px(40),
              maxWidth: px(540),
              [theme.MediaQueries.desktop]: {
                maxWidth: px(960),
                flexDirection: "row",
                paddingTop: px(40),
                paddingBottom: px(40),
              },
            })}
          >
            <div className={css({ paddingRight: px(90) })}>
              <Header3
                $style={{
                  marginBottom: px(20),
                  [theme.MediaQueries.desktop]: {
                    fontSize: px(44),
                    lineHeight: px(44),
                  },
                }}
              >
                Test Your Knowledge
              </Header3>
              <Content
                $style={{
                  marginBottom: px(50),
                  [theme.MediaQueries.desktop]: { marginBottom: 0 },
                }}
              >
                {options.showIncentive
                  ? "Let’s see if you can truly miss nothing by putting your Predator Accuracy knowledge to the test for a chance to win."
                  : "Let’s see if you can truly miss nothing by putting your Predator Accuracy knowledge to the test."}
              </Content>
            </div>
            <div
              className={css({
                [theme.MediaQueries.desktop]: { alignSelf: "flex-end" },
              })}
            >
              <Button
                variant="tertiary"
                $as="a"
                $style={{
                  backgroundColor: "#E3157E",
                  borderColor: "#E3157E",
                }}
                href={SURVEY_LINK}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  tag("survey_clicked", {
                    shoe: "PredatorAccuracy",
                    url: e.currentTarget.href,
                  });
                }}
                openExternally={true}
              >
                Take the Survey
                <RightArrow />
              </Button>
            </div>
          </div>
        </section>
        <section
          className={css({
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            marginTop: px(60),
            marginBottom: px(30),
            [theme.MediaQueries.desktop]: {
              width: "100%",
              maxWidth: px(1150),
              paddingLeft: px(0),
              paddingRight: px(0),
              marginTop: px(100),
              marginBottom: px(100),
            },
          })}
        >
          <div
            className={css({
              minWidth: px(320),
              maxWidth: px(320),
              paddingLeft: px(35),
              paddingRight: px(35),
              [theme.MediaQueries.desktop]: {
                minWidth: "100%",
                maxWidth: "100%",
                paddingLeft: px(0),
                paddingRight: px(0),
              },
            })}
          >
            <Header3
              $style={{
                marginBottom: px(40),
                [theme.MediaQueries.desktop]: {
                  textAlign: "center",
                  marginBottom: px(30),
                },
              }}
            >
              Learn More
            </Header3>
          </div>
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              minWidth: px(320),
              maxWidth: px(320),
              paddingLeft: px(35),
              paddingRight: px(35),
              [theme.MediaQueries.desktop]: {
                paddingLeft: px(0),
                paddingRight: px(0),
                flexDirection: "row",
                minWidth: "100%",
                maxWidth: "100%",
              },
            })}
          >
            <div className={css({ flex: 1 })} />
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                minWidth: px(240),
                maxWidth: px(240),
                marginBottom: px(45),
                [theme.MediaQueries.desktop]: {
                  minWidth: px(320),
                  maxWidth: px(320),
                  marginBottom: px(0),
                },
              })}
            >
              <img
                className={css({
                  width: "100%",
                  marginBottom: px(20),
                  [theme.MediaQueries.desktop]: { marginBottom: px(30) },
                })}
                src={imageCopa}
                alt="Copa Pure"
              />

              <Header3
                $style={{
                  marginBottom: px(10),
                  [theme.MediaQueries.desktop]: {
                    marginBottom: px(24),
                    fontSize: px(50),
                    lineHeight: px(55),
                  },
                }}
              >
                Copa Pure
              </Header3>

              <Content
                $style={{
                  color: "#898989",
                  marginBottom: px(25),
                  [theme.MediaQueries.desktop]: { marginBottom: px(40) },
                }}
              >
                Play Like You Used To
              </Content>

              <div className={css({ flex: 1 })} />

              <Link
                to={`${getSoccerBasePath()}copa/`}
                $style={{ color: "#000000" }}
              >
                Learn More <RightArrow />
              </Link>
            </div>
            <div className={css({ minWidth: px(30), maxWidth: px(30) })} />
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                minWidth: px(240),
                maxWidth: px(240),
                marginBottom: px(45),
                [theme.MediaQueries.desktop]: {
                  minWidth: px(320),
                  maxWidth: px(320),
                  marginBottom: px(0),
                },
              })}
            >
              <img
                className={css({
                  width: "100%",
                  marginBottom: px(20),
                  [theme.MediaQueries.desktop]: { marginBottom: px(30) },
                })}
                src={imageXSpeedportal}
                alt="X Speedportal"
              />

              <Header3
                $style={{
                  marginBottom: px(10),
                  [theme.MediaQueries.desktop]: {
                    marginBottom: px(24),
                    fontSize: px(50),
                    lineHeight: px(55),
                  },
                }}
              >
                X Speedportal
              </Header3>

              <Content
                $style={{
                  color: "#898989",
                  marginBottom: px(25),
                  [theme.MediaQueries.desktop]: { marginBottom: px(40) },
                }}
              >
                Faster in All Dimensions
              </Content>

              <div className={css({ flex: 1 })} />

              <Link
                to={`${getSoccerBasePath()}x-speedportal/`}
                $style={{ color: "#000000" }}
              >
                Learn More <RightArrow />
              </Link>
            </div>

            <div className={css({ flex: 1 })} />
          </div>
        </section>
      </main>
      <footer
        className={css({
          display: "flex",
          flexDirection: "column",
        })}
      >
        <section
          className={css({
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "100vw",
            alignSelf: "center",
            backgroundColor: "#000000",
            paddingTop: px(40),
            [theme.MediaQueries.desktop]: {
              justifyItems: "center",
              width: "100%",
            },
          })}
        >
          <div
            className={css({
              maxWidth: px(320),
              marginBottom: px(40),
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              [theme.MediaQueries.desktop]: {
                width: "100%",
                maxWidth: px(620),
                justifyContent: "center",
                alignSelf: "center",
                display: "flex",
                flexWrap: "wrap",
              },
            })}
          >
            <Button
              $style={{
                display: "inline-flex",
                marginBottom: px(20),
                backgroundColor: "#E3157E",
                borderColor: "#E3157E",
                [theme.MediaQueries.desktop]: { marginRight: px(20) },
              }}
              variant="tertiary"
              $as="a"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                tag("asset_downloaded", {
                  shoe: "PredatorAccuracy",
                  url: e.currentTarget.href,
                });
              }}
              href={withAssetPrefix("/adidas_PredatorAccuracy.3.zip")}
              openExternally={true}
              download="PredatorAccuracy_training_sheets.zip"
            >
              Download Assets
              <RightArrow />
            </Button>

            <Button
              $style={{
                color: "#E3157EFF",
                borderColor: "#E3157EFF",
                backgroundColor: "transparent",
                ":hover": {
                  color: "#E3157E99",
                },
                display: "inline-flex",
                marginBottom: px(20),
              }}
              $as="a"
              href={SURVEY_LINK}
              variant="primary"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                tag("survey_clicked", {
                  shoe: "PredatorAccuracy",
                  url: e.currentTarget.href,
                });
              }}
              openExternally={true}
            >
              Take the Survey
              <RightArrow />
            </Button>

            <Button
              $style={{ display: "inline-flex" }}
              $as="a"
              variant="secondary"
              href={global.window.location.href}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                tag("share_clicked", {
                  shoe: "PredatorAccuracy",
                  url: global.window.location.href,
                });
                triggerShare(global.window.location.href)(e);
              }}
            >
              Share Training
              <RightArrow />
            </Button>
          </div>
        </section>
      </footer>
    </>
  );
};

export default ContentAdiSoccerPredatorAccuracy;
