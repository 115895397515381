import Player from "@vimeo/player";
import "normalize.css";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useStyletron } from "styletron-react";
import { RetailerContext } from "../contexts/RetailerContext";
import { ShareContext } from "../contexts/ShareContext";
import { ThemeContext } from "../contexts/ThemeContext";
import VideoContext from "../contexts/VideoContext";
import { useRetailer } from "../hooks/useRetailer";
import { useTag } from "../hooks/useTag";
import iconFavIcon from "../images/favicon.ico";
import CONSTANTS from "../utilities/CONSTANTS";
import { pct } from "../utilities/pct";
import { px } from "../utilities/px";
import BorderedButton from "./BorderedButton";
import "./Page.css";

export const HOVER_OPACITY = Math.round(255 * 0.6).toString(16);

export const DEFAULT_THEME = {
  Video: {
    primary: CONSTANTS.RED_NO_HASH,
  },
  Button: {
    primary: {
      backgroundColor: "#FFFFFFFF",
      foregroundColor: "#000000FF",
      hoverColor: `#000000${HOVER_OPACITY}`,
    },
    secondary: {
      backgroundColor: "#000000FF",
      foregroundColor: "#FFFFFFFF",
      hoverColor: `#FFFFFF${HOVER_OPACITY}`,
    },
    tertiary: {
      backgroundColor: "#E63320FF",
      foregroundColor: "#FFFFFFFF",
      hoverColor: `#FFFFFF${HOVER_OPACITY}`,
    },
  },
  BorderedButton: {
    primary: {
      backgroundColor: "#FFFFFFFF",
      foregroundColor: "#000000FF",
      hoverColor: `#000000${HOVER_OPACITY}`,
    },
    secondary: {
      backgroundColor: "#000000FF",
      foregroundColor: "#FFFFFFFF",
      hoverColor: `#FFFFFF${HOVER_OPACITY}`,
    },
    tertiary: {
      backgroundColor: "#FFFFFFFF",
      foregroundColor: "#E63320FF",
      hoverColor: `#E63320${HOVER_OPACITY}`,
    },
  },
  MediaQueries: {
    desktop: `@media screen and (min-width: ${px(880)})`,
  },
};

const Page = ({
  children,
  title,
  theme = DEFAULT_THEME,
}: {
  children: any;
  title: string;
  theme?: typeof DEFAULT_THEME;
}) => {
  const { retailer, setRetailer } = useRetailer();
  const [video, setVideo] = useState<
    { id: string; url: string; hash?: string } | undefined
  >();
  const [css] = useStyletron();
  const textShareRef = useRef(null);
  const { tag } = useTag();

  useEffect(() => {
    if (
      global.window !== undefined &&
      (global.window as any).gtag !== undefined
    ) {
      (global.window as any).gtag("config", "G-967710TVVL", {
        retailer,
      });
    }
  }, [retailer]);

  useEffect(() => {
    tag("page_load", { page_path: location.pathname });
  }, []);

  return (
    <>
      <Helmet
        bodyAttributes={{
          ["data-video"]: video ? "open" : "closed",
        }}
      >
        <link rel="icon" href={iconFavIcon}></link>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
      </Helmet>
      <RetailerContext.Provider value={{ retailer, setRetailer }}>
        <ThemeContext.Provider value={theme}>
          <VideoContext.Provider value={{ setVideo }}>
            <ShareContext.Provider
              value={{
                triggerShare: (url: string) => {
                  return async (e: Event) => {
                    e.preventDefault();
                    if (global?.navigator?.share) {
                      await global?.navigator?.share({
                        url: url,
                        title,
                      });
                    } else {
                      textShareRef.current.value = url;
                      textShareRef.current.select();
                      global?.document?.execCommand("copy");
                      global?.window?.alert("URL copied!");
                    }
                  };
                },
              }}
            >
              <div
                className={css({
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                })}
              >
                {children}
              </div>
              <div
                onClick={() => setVideo(undefined)}
                className={css({
                  position: "fixed",
                  top: video ? 0 : "-100%",
                  opacity: video ? 1 : 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#000000CC",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transition: !video
                    ? "top 0s 0.5s, opacity 0.5s"
                    : "top 0s, opacity 0.5s 0.1s",
                })}
              >
                <div
                  className={css({
                    width: "100%",
                    maxWidth: px(960),
                    marginLeft: px(20),
                    marginRight: px(20),
                  })}
                >
                  {video?.id && (
                    <div
                      className={css({
                        position: "relative",
                        height: 0,
                        paddingBottom: pct(540 / 960),
                      })}
                    >
                      <ThemeContext.Consumer>
                        {(theme) => (
                          <iframe
                            allow="autoplay"
                            className={css({
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              borderWidth: 0,
                            })}
                            onLoad={(
                              e: React.SyntheticEvent<HTMLIFrameElement>
                            ) => {
                              const player = new Player(e.currentTarget);
                              const onTimeUpdate = ({ percent }) => {
                                if (percent > 0.9) {
                                  player.off("timeupdate", onTimeUpdate);
                                  // tag("video_finished", {
                                  //   id: video.id,
                                  //   url: video.url,
                                  // });
                                }
                              };
                              player.on("timeupdate", onTimeUpdate);
                              player.play();
                            }}
                            src={`https://player.vimeo.com/video/${video.id}?color=${theme.Video.primary}&byline=false&title=false&portrait=false&h=${video.hash}`}
                          />
                        )}
                      </ThemeContext.Consumer>
                    </div>
                  )}
                </div>
              </div>
              <input
                className={css({
                  opacity: 0,
                  position: "fixed",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                })}
                ref={textShareRef}
              />
              <div
                className={css({
                  position: "fixed",
                  zIndex: 1,
                  bottom: "1rem",
                  right: "1rem",
                  display:
                    process.env.ENVIRONMENT === "development"
                      ? "block"
                      : "none",
                })}
              >
                <BorderedButton onClick={() => setRetailer(null)}>
                  Clear
                </BorderedButton>
              </div>
            </ShareContext.Provider>
          </VideoContext.Provider>
        </ThemeContext.Provider>
      </RetailerContext.Provider>
    </>
  );
};

export default Page;
